import BackCompatBooleanDisplay from './BackwardCompatible/BooleanDisplay'
import BackCompatBooleanWaterDisplay from './BackwardCompatible/BooleanWaterDisplay'
import BackedMeter from './BackwardCompatible/Meter'
import MultiStepBooleanWaterDisplay from './MultiStep/MultiStepWaterDisplay'
import BackCompatNumberDisplay from './BackwardCompatible/NumberDisplay'
import BackCompatStringDisplay from './BackwardCompatible/StringDisplay'
import MultiStepNumberDisplay from './MultiStep/MultiStepNumberDisplay'
import MultiStepBooleanDisplay from './MultiStep/MultiStepBooleanDisplay'

import StaticGauge from './StaticData'

const Gauge = {
  BackCompatBooleanDisplay,
  BackCompatBooleanWaterDisplay,
  BackedMeter,
  MultiStepBooleanWaterDisplay,
  BackCompatNumberDisplay,
  BackCompatStringDisplay,
  MultiStepNumberDisplay,
  MultiStepBooleanDisplay,
  StaticGauge,
}

export default Gauge
