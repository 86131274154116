import React, { useRef, useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

import { useDisclosure } from '@chakra-ui/react'

import moment from 'moment'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
import Notifications from '../../../components/Notifications/Notifications'

import DisplayTimeControl from './DisplayTimeControl'
import ThresholdControl from './ThresholdControl'
import { isDisabled } from '@chakra-ui/utils'

export default function ControlLists({
  currentStation,
  isControlEnable,
  setIsLoadingControlsRes,
  isLoadingControlsRes,
  nameStation,
}) {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const notifyRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [openModal, setOpenModal] = useState([
    { status: false, name: 'test_timer' },
    { status: false, name: 'test_timer2' },
  ])

  const handleOpenModal = (name, index) => {
    const sensorName = _.filter(openModal, (each) => each.name === name)
    console.log('name', sensorName)
    setOpenModal(sensorName)
  }

  const onSubmitDay = (controlRef, payload) => {
    alert('บันทึกข้อมูลสำเร็จ')
    console.log('Payload', payload)
    dispatch(
      actions.timerControl(currentStation.externalRef, controlRef, payload)
    )
  }

  // สำหรับตั้งค่า Threshold
  const { register, handleSubmit } = useForm()
  const enableSwitchThreshold = (controlRef, data) => {
    console.log('Threshold Control ', controlRef)
    alert('บันทึกข้อมูลสำเร็จ')

    const enableSwitchThreshold = (controlRef, data) => {
      console.log('Save ThresholdControl ', data)
      let payload = {
        enabled: data.enabled,
        on: parseFloat(data.on),
        off: parseFloat(data.off),
      }
      console.log('Threshold Control ', payload)
      dispatch(
        actions.thresholdControl(
          currentStation.externalRef,
          controlRef,
          payload
        )
      )
    }
  }
  // สำหรับสั่งงาน ON OFF
  const handleSwitchControl = (
    eventValue,
    controlRef,
    sensorData,
    isHighSensitiveSensor = false,
    isMajorControl = false
  ) => {
    let confirm

    if (isHighSensitiveSensor === true) {
      confirm = true
      // confirm = window.confirm("ยืนยันการสั่งการ")
    } else {
      confirm = true
    }

    if (confirm === true) {
      if (sensorData.controlWithPulse === true) {
        //สำหรับเซ็นเซอร์ที่เป็น pulse
        dispatch(
          actions.toggleSensorWithPulse(currentStation.externalRef, controlRef)
        )
      } else {
        if (eventValue === true) {
          //สำหรับทุก ๆ เซ็นเซอร์
          dispatch(actions.turnSensorOn(currentStation.externalRef, controlRef))
        } else {
          if (isMajorControl === true) {
            //เมื่อปิดออนไลน์ทุกอย่างจะต้องการกลับมาเป็น default ของหน้างาน ( ไม่เกี่ยวกับซอฟแวร์แล้ว )
            console.log('Major')
            dispatch(
              actions.turnMajorSensorOff(currentStation.externalRef, controlRef)
            )
          } else {
            dispatch(
              actions.turnSensorOff(currentStation.externalRef, controlRef)
            )
          }
        }
      }
    }

    console.log('Event Value', eventValue)
  }

  const genControltLists = () =>
    _.map(
      _.orderBy(
        _.filter(
          currentStation.sensors,
          (sensor) =>
            sensor.sensorType === 'BOOLEAN' &&
            sensor.gaugeType === 'OnOffGauge' &&
            sensor.controlRefString !== 'CONTROLS_ENABLE'
        ),
        { orderLevel: 'asc' }
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td> {sensor.name} </Td>
          <Td>
            <Switch
              size="lg"
              defaultChecked={sensor.booleanStatus}
              isDisabled={!isControlEnable}
              onChange={(event) => {
                handleSwitchControl(
                  event.target.checked,
                  sensor.controlRefString,
                  sensor,
                  false
                )
                notifyRef.current.createNotification(
                  'info',
                  '',
                  'กำลังสั่งงาน...'
                )
                setIsLoadingControlsRes(false)
              }}
            />
          </Td>
        </Tr>
      )
    )

  const genMajorCOntrol = () =>
    _.map(
      _.orderBy(
        _.filter(
          currentStation.sensors,
          (sensor) =>
            sensor.sensorType === 'BOOLEAN' &&
            sensor.gaugeType === 'OnOffGauge' &&
            sensor.controlRefString === 'CONTROLS_ENABLE'
        ),
        { orderLevel: 'asc' }
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td className="text-lg font-semibold"> {sensor.name} </Td>
          <Td>
            <Switch
              size="lg"
              defaultChecked={sensor.booleanStatus}
              isDisabled={!isControlEnable}
              onChange={(event) => {
                handleSwitchControl(
                  event.target.checked,
                  sensor.controlRefString,
                  sensor,
                  false,
                  false
                )
                setIsLoadingControlsRes(false)
              }}
            />
          </Td>
        </Tr>
      )
    )

  //สร้างปุ่ม ON OFF
  const genOneValueControlList = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === 'BOOLEAN' && sensor.gaugeType === 'TriggerGague'
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td> {sensor.name} </Td>
          <Td>
            {sensor.booleanStatus ? (
              <Button
                size="sm"
                isDisabled={!isControlEnable}
                colorScheme="green"
                defaultChecked={sensor.booleanStatus}
                onClick={(event) => {
                  handleSwitchControl(
                    false,
                    sensor.controlRefString,
                    sensor,
                    false
                  )
                  setIsLoadingControlsRes(false)
                  // setTimeout(()=>{setIsLoadingControlsRes(false);},1000)
                }}
              >
                {t('control.on')}
              </Button>
            ) : (
              <Button
                size="sm"
                isDisabled={!isControlEnable}
                colorScheme="red"
                defaultChecked={sensor.booleanStatus}
                onClick={(event) => {
                  handleSwitchControl(
                    true,
                    sensor.controlRefString,
                    sensor,
                    true
                  )
                  setIsLoadingControlsRes(true)
                  // setTimeout(()=>{setIsLoadingControlsRes(true);},1000)
                }}
              >
                {t('control.off')}
              </Button>
            )}
          </Td>
        </Tr>
      )
    )

  // สร้าง Timer Control
  const genTimerControl = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === 'BOOLEAN' && sensor.gaugeType === 'TimerControl'
      ),
      (sensor, index) => (
        <Accordion allowMultiple>
          <AccordionItem>
            <div
              key={index}
              className="border-2 border-gray-400 rounded-md shadow-lg "
            >
              <AccordionButton sx={{ bg: '#edf2f7	', color: 'black' }}>
                <div className="flex w-full">{sensor.name}</div>

                <div className="flex justify-end">
                  {' '}
                  <AccordionIcon />
                </div>
                {/* <Td> {index + 1} </Td> */}
              </AccordionButton>
            </div>

            <AccordionPanel>
              <DisplayTimeControl
                sensor={sensor}
                onSubmitDay={onSubmitDay}
                defaultTimer={sensor.timers}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )
    )

  // สร้างคอนโทรลแบบตั้งค่า Threshold
  const genThesholdControl = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === 'BOOLEAN' &&
          sensor.gaugeType === 'ThresholdControl'
      ),
      (sensor, index) => (
        <ThresholdControl
          sensor={sensor}
          enableSwitchThreshold={enableSwitchThreshold}
        />
      )
    )

  return (
    <>
      <div>
        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              {/* <Th>#</Th> */}
              <Th>{t('setting.sensor')}</Th>
              <Th>{t('control.onOff')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {genMajorCOntrol()}
            {genControltLists()}
            {genOneValueControlList()}
          </Tbody>
        </Table>
      </div>
      {genThesholdControl()}
      {genTimerControl()}
    </>
  )
}
